body {
  background-image: url(https://cdn.discordapp.com/attachments/953432878560186398/959524143076868136/lfg2.png);
  font-weight: normal;
  height: 150%;
  width: 150%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: center center; 
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1em;
  margin: 0;
  width: 100vw;
  height: 100vh;
}
/* 
.outer {
  padding: 10px;
} */

.container {
  /* width: 100%; */
  margin: auto;
  /* border: 5px #000000 solid; */
  padding: 10px;
  border-radius: 25px;
}

.input {
  width: 1;
  color: #777;
  padding: 8;
  margin: 10;
  width: 200;
}

.myform {
  padding: 20px;
}

.myform input[type="text"],
.myform textarea {
  padding: 8px;
  width: 100%;
}

.column {
  padding: 20px;
  float: left;
  width: 50%;
}

.column_aut {
  padding: 20px;
  float: left;
  width: 33%;
}

.Token_message {
  display: inline;
}

.swap {
  padding: 10px;
}

.swap_button {
  float: right;
}

.markdown {
  width: 80%;
  margin: auto;
}
