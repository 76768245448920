.Title {
  height: 50px;
  text-align: center;
  font-size: 1rem;
}

.NavbarItems {
  height: 80px;
  display: flex;

  align-items: center;
  font-size: 1.2rem;

  text-align: left;
  margin-left: 40%;
  color: white;
}

.navbar-logo {
  color: rgb(255, 255, 255);
  justify-content: center;
  margin-left: 0px;
  cursor: pointer;
}

.logo {
  width: 230px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 15%;
  list-style: none;
  /* text-align: left; */
  /* justify-content: end; */
  padding-top: 1rem;
  padding-left: 0rem;
  font-size: 25px;
}

.nav-links {
  color: rgb(201, 201, 201);
  text-decoration: none;
}

.nav-links-clicked {
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.nav-links:hover {
  color: rgb(150, 150, 150);
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
